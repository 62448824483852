footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 100px;
}

footer span {
    color: white;
}