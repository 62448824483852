.project-div {
    /* background-color: #1a1a1a; */
    background-color: black;
    /* min-height: 100vh; */
    padding: 20px;
    font-family: "Ubuntu", sans-serif;
    color: white;
}

.projects-content {
    margin: 0 auto;
    width: 90%;
    max-width: 1200px;
    text-align: center;
}

.projects-title {
    font-size: 28px;
    margin-bottom: 30px;
    letter-spacing: 1px;
    color: #e0e0e0;
}

/* Desktop Projects Layout */
.projects {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 60px;
}

.project-item {
    display: flex;
    align-items: center;
    gap: 30px;
    background-color: #2a2a2a;
    padding: 20px;
    /* border-radius: 12px; */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
}

.project-item:hover {
    transform: scale(1.02);
}

.project-item img {
    width: 250px;
    height: 200px;
    /* border-radius: 8px; */
    object-fit: fill;
}

.project-description {
    text-align: left;
    flex: 2;
}

.project-description h4 {
    margin: 0 0 10px 0;
    font-size: 20px;
    color: #f5f5f5;
}

.project-description p {
    line-height: 1.6;
    margin-bottom: 8px;
}

.project-description ul {
    list-style: disc;
    padding-left: 20px;
    margin-top: 10px;
}

/* Mobile Projects Layout */
.projects-mobile {
    display: none;
    margin-top: 20px;
}

.projects-title-mobile {
    display: none;
}

.project-item-mobile {
    text-align: center;
    margin-top: 30px;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.item-mobile-img img {
    width: 100%;
    max-width: 400px;
    height: 250px;
    transition: transform 0.3s ease;
}

.item-mobile-name {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgb(42, 41, 41);
    padding: 1px;
}

.item-mobile-name p {
    font-weight: normal;
    font-size: 16px;
    color: white !important;
}

.project-item-mobile:hover {
    transform: scale(1.04);
}

/* Media Queries */
@media (max-width: 767px) {
    .projects {
        display: none;
    }
    .projects-mobile {
        display: block;
    }
    .projects-title {
        display: none;
    }
    .projects-title-mobile {
        display: block;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .projects-content {
        margin: 0 auto;
        width: 80%;
        max-width: 1200px;
        text-align: center;
    }
    .project-item {
        flex-direction: column;
        text-align: center;
    }
    .project-item img {
        width: 80%;
        height: auto;
    }
}
