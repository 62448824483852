.contact-div {
    background-color: black;
    min-height: 100vh;
    padding-top: 20px;
    font-family: "Ubuntu", sans-serif;
    color: white;
    padding-bottom: 20px;
}

.contact-message-block {
    /* position: absolute;
    left: 50%;
    top: 15%;
    transform: translateX(-50%);
    max-width: 400px; /* Restrict the block's maximum width */
    /* padding: 10px; */
    position: relative; /* Change to relative to avoid absolute positioning */
    margin: 0 auto; /* Center on the screen */
    max-width: 500px; /* Restrict the block's maximum width */
    padding: 10px; /* Add some padding */
    margin-top: 10%;
}

.message-text-boxes {
    height: auto; /* Allow height to adjust dynamically */
    width: 90%; /* Use a percentage for responsiveness */
    max-width: 400px; /* Restrict the maximum width */
    margin: 0 auto; /* Center the box */
}

.text-div {
    margin: 20px 0 20px;
}

.text-div label {
    color: grey;
    margin-bottom: 10px;
}

.text-div input {
    height: 45px;
}

.text-div input, textarea {
    border-radius: 3px;
    font-family: "Ubuntu", sans-serif;
    border: 0;
    padding: 7px;
    width: 100%; /* Take up full width of the parent */
    box-sizing: border-box; /* Include padding and border in the width */
    font-size: 15px;
}

.text-div input::placeholder,
.text-div textarea::placeholder {
    color: rgb(171, 165, 165);
}

.contact-div button {
    border: 1px solid grey;
    padding: 8px 20px 8px;
    color: white;
    background-color: black;
    border-radius: 3px;
    cursor: pointer;
}

.contact-div button:hover {
    background-color: grey;
}

@media (max-width: 600px) {
    .contact-message-block {
        width: 90%; /* Allow the block to scale on smaller screens */
        margin-top: 25%;
    }
    .message-text-boxes {
        width: 100%; /* Ensure full width on smaller screens */
    }
}