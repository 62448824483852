.project-details-div {
    background-color: black;
    min-height: 100vh;
    padding-top: 20px;
    font-family: "Ubuntu", sans-serif;
    color: white;
    padding-bottom: 20px;
}

.project-details-content {
    margin: 0 auto;
    width: 100%;
    width: 100%;
}

.project-details {
    margin-top: 20%;
}

.project-details-item {
    margin-bottom: 20px;
    text-align: center;
    background-color: black;
    margin-top: 30px;
    width: 100%;
}

.project-details-item ul {
    line-height: 1.3em;
}

.project-details-item img {
    width: 100%; 
    aspect-ratio: 1 / 1; /* Ensures a 1:1 ratio (height = width) */
    object-fit: fill; /* Ensures the image fills the space */
}

.details-img {
    margin: 0 5% 0;
}

.details-paragraph {
    padding: 0 10px 0;
    text-align: center;
    color: white !important;
}