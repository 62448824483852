.home-div {
    background-color: black;
    min-height: 100vh;
    font-family: "Ubuntu", sans-serif;
    padding-bottom: 20px;
}

.home-content {
    margin: 0 10% 0;
}

.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    justify-content: center;
}
  
.small-about {
  display: none;
}

  h3 {
    color: white;
  }
  
  p {
    color: rgb(215, 215, 215) !important;
  }
  
  .skills-div {
    padding: 10px 20px;
    background-color: black;
    border-radius: 8px;
  }
  
  .skills-list {
    list-style-type: none;
    padding-left: 8px;
    margin: 0;
    font-size: 1.1em;
  }
  
  .skills-list li {
    color: white;
    padding: 5px 0;
    border-bottom: 1px solid #bdc3c7;
    transition: color 0.3s ease;
  }
  
  .skills-list li:last-child {
    border-bottom: none;
  }
  
  .skills-list li:hover {
    color: #1abc9c;
  }
  
  .about, .website, .certificates {
    margin-bottom: 40px;
  }
  
  .skills-div {
    padding: 5px;
  }
  
  .skills-div li {
    color: white;
  }
  
  .download-button {
    margin-top: 20px;
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    border: 1px solid grey;
  }
  
  .download-button a {
    color: white;
    text-decoration: none;
  }
  
  .download-button:hover {
    background-color: grey;
  }
  
  .certificates {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Space between project items */
    justify-content: center;
    line-height: 1.4;
  }
  
  .certificate-item {
    display: flex; /* Align items horizontally */
    align-items: center; /* Vertically align image and text */
    gap: 50px; /* Space between image and text */
    width: 70%; /* Fixed width for the project item */
    max-width: 100%; /* Ensure it doesn't overflow the container */
    margin-bottom: 70px;
    font-size: 17px;
  }
  
  .certificate-item img {
    width: 300px; /* Fixed width for the image */
    height: 250px; /* Maintain aspect ratio */
    display: block;
    /* border-radius: 8px; */
  }
  
  .certificate-item p {
    margin: 0;
    word-wrap: break-word; /* Ensure long words break correctly */
    flex: 1; /* Allow the paragraph to grow */
  }
  
  .certificates-mobile {
    font-family: 'Poppins', sans-serif;
    display: none;
    line-height: 1.4;
  }
  
  .certificate-item-mobile {
    text-align: center; /* Center align content */
    /* margin-bottom: 50px; */
  }

  .certificate-item-mobile img {
    width: 100%; /* Makes the image responsive */
    height: 250px; /* Maintains aspect ratio */
    margin-bottom: 20px; /* Adds space between image and text */
    /* border-radius: 8px; */
  }
  
  .certificate-item-mobile p {
    margin: 0 0 10px 0; /* Adds spacing between paragraph and list */
    text-align: left; /* Align text to the left */
    margin: 0 auto;
  }
  
  .certificate-item-mobile ul {
    list-style-type: disc;
    padding-left: 20px; /* Adds indentation for list items */
    text-align: left; /* Align list text to the left */
    color: white;
    margin: 0 auto;
  }
  
  @media (max-width: 767px) {
    .content {
      margin-left: 5%;
      margin-right: 5%;
    }
    .big-about {
      display: none;
    }
    .small-about {
      display: block;
    }
    .about {
      width: 100%;
    }
    .certificates {
      display: none;
    }
    .certificates-mobile {
      display: block;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .home-content {
      margin: 0 3% 0
    }
    .about {
      width: 85% !important;
    }
    .certificate-item {
      width: 100% !important;
    }
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1439px) {
    .home-content {
      margin: 0 3% 0;
    }
    .about {
      width: 80% !important;
    }
    .about p {
      font-size: 20px !important;
    }
    .certificate-item {
      width: 90% !important;
    }
  }
  
  @media (prefers-reduced-motion: no-preference) {
    
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }