#menu-icon {
  display: none !important;
}

.linkedin-link {
  margin-top: 10px;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  display: inline;
}

button {
  margin-right: 0 !important;
}

.linkedin-link a {
  color: white;
  text-decoration: none;
  /* margin-left: 5px; */
}

#projects-link:hover {
  background-color: rgb(45, 42, 42);
}

@media (max-width: 767px) {
    #nav-toolbar {
        width: 90% !important;
    }
    #menu-icon {
      display: block !important;
    }
    .mobile-buttons {
      display: none;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .mobile-buttons {
      display: none;
    }
    #nav-toolbar {
        width: 90% !important;
    }
    #menu-icon {
      display: block !important;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1439px) {
    #nav-toolbar {
        width: 95% !important;
    }
  }